.header {
    display: flex;
    justify-content: space-between;
    padding: 0 3%;
}

.toolbar {
    display: flex;
    margin: auto 0;
    font-size: smaller;
}

.toolbar-item {
    text-decoration: none;
    margin: auto 0;
    display: block;
    margin-left: 2em;
}

.toolbar-item:hover {
    cursor: pointer;
}