.footer {
    padding-bottom: 1.5em;
    display: flex;
    justify-content: space-evenly;
}

.footer-title {
    text-align: center;
}

.footer-content {
    font-size: smaller;
    display: flex;
    justify-content: space-around;
}

a {
    color: currentColor;
}

a:visited {
    color: currentColor;
}