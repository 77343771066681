.image-count {
    font-size: smaller;
    display: flex;
    justify-content: space-between;
    width: calc(100% - 20px);
    margin: auto;
}

.image-count div {
    margin: auto 0;
}

.image-count div:hover {
    cursor: pointer;
}

.disabled {
    opacity: 50%;
}

.disabled:hover {
    cursor: default !important;
}

.carousel {
    overflow: hidden;
    max-width: 600px;
    width: 50%;
    margin: auto;
}

.inner {
    margin: 0;
    white-space: nowrap;
    transition: transform 0.2s;
}

.carousel-item {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: calc(100% - 20px);
    margin: 10px;
    border-radius: 5px;
    box-shadow: 
        0 1px 2px 0 rgba(0, 0, 0, 0.15),
        0 2px 4px 0 rgba(0, 0, 0, 0.12),
        0 4px 8px 0 rgba(0, 0, 0, 0.09);
}

.carousel-item img {
    width: 100%;
    border-radius: 5px 5px 0 0;
}

.page-number {
    padding: 0 0.25em;
}

.active {
    font-weight: bolder;
    font-size: larger;
    cursor: default !important;
}

figure {
    padding: 0;
    margin: 0;
}
  
figcaption {
    text-align: center;
    font-size: smaller;
    margin: 0.75em;
    white-space: normal;
}

@media (max-width: 900px) {
    .carousel {
        width: 75%;
    }
}

@media (max-width: 500px) {
    .carousel {
        width: 100%;
    }
}