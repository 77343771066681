@font-face {
  font-family: font;
  src: url(../fonts/Aaargh.ttf);
}

:root {
  --light-color: rgb(6, 35, 28);
  --light-background-color: rgba(237, 252, 249, 0.5);
  --dark-color: rgb(244, 244, 246);
  --dark-background-color: rgb(56, 59, 66)
}

body {
  font-family: font;
  margin: 0px;
  padding: 0;
  font-size: 20px;
}

.container-text {
  width: 50%;
  max-width: 600px;
  margin: auto;
}

.container {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-evenly;
  height: fit-content;
}

@media (max-width: 10px) {
  .container {
    display: inline-block;
  }

  .container-text {
    display: inline;
  }
}

@media (max-width: 900px) {
  .container {
    flex-direction: column;
  }

  .container-text {
    width: 100%;
  }

  figure {
    margin: auto;
  }
}

@media (max-width: 600px) {
  body {
    font-size: 13px;
  }
}