.light {
  height: 100%;
  background-color: var(--light-background-color);
  color: var(--light-color);
}

.dark {
  height: 100%;
  background-color: var(--dark-background-color);
  color: var(--dark-color);
}