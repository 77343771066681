.with-padding {
  padding: 10px 7.5%;
}

.sub-card {
  padding: 10px 0;
}

.sticky {
  position: sticky;
  top: 0;
  background-color: rgba(255, 255, 255, 0);
  backdrop-filter: blur(25px);
  z-index: 1;
}

.card-title {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 0;
}

.header-extra {
  margin: auto 0;
  font-size: smaller;
}